import React, { Component } from 'react'
import * as Icon from 'react-feather'
import Menu from '../menu/menu.js'
import styles from './toggleMenu.module.scss'

class ToggleMenu extends Component {
  constructor(props) {
    super(props)

    this.toggleMenu = this.toggleMenu.bind(this)

    this.state = {
      open: false,
    }
  }

  toggleMenu() {
    this.setState({
      openMenu: !this.state.openMenu,
    })
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.toggleMenu} onClick={this.toggleMenu}>
          <Icon.Menu />
        </div>
        <div
          onClick={this.toggleMenu}
          className={this.state.openMenu ? styles.openMenu : styles.closedMenu}
        >
          <Menu />
        </div>
        <div
          onClick={this.toggleMenu}
          className={
            this.state.openMenu
              ? styles.openMenuBackground
              : styles.closedMenuBackground
          }
        ></div>
      </div>
    )
  }
}

export default ToggleMenu
