import React, { Component } from 'react'
import Link from 'gatsby-link'
import { Plus } from 'react-feather'
import styles from './menu.module.scss'

class Menu extends Component {
  constructor(props) {
    super(props)

    this.toggleAbout = this.toggleAbout.bind(this)
    this.toggleTrips = this.toggleTrips.bind(this)
    this.toggleGallery = this.toggleGallery.bind(this)

    this.state = {
      open: false,
    }
  }

  toggleAbout() {
    this.setState({ openAbout: !this.state.openAbout })
  }

  toggleTrips() {
    this.setState({ openTrips: !this.state.openTrips })
  }
  toggleGallery() {
    this.setState({ openGallery: !this.state.openGallery })
  }

  render() {
    return (
      <div className={styles.menu}>
        <ul>
          <li>
            <div className={styles.parent}>
              <Link to="/about/">About</Link>
              <div
                className={this.state.openAbout ? styles.more : styles.less}
                onClick={this.toggleAbout}
              >
                <Plus />
              </div>
            </div>
            <ul className={this.state.openAbout ? styles.open : styles.closed}>
              <li>
                <Link to="/about#bio">Bio</Link>
              </li>
              <li>
                <Link to="/about#awards">Awards</Link>
              </li>
              <li>
                <Link to="/about#books">Books</Link>
              </li>
              <li>
                <Link to="/about#guided-trips">Guided Trips</Link>
              </li>
              <li>
                <Link to="/about#talks">Talks</Link>
              </li>
              <li>
                <Link to="/about#workshops">Workshops</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/shop/">Shop</Link>
          </li>
          <li>
            <div className={styles.parent}>
              <Link to="/trips-events/">Trips & Events</Link>
              <div
                className={this.state.openTrips ? styles.more : styles.less}
                onClick={this.toggleTrips}
              >
                <Plus />
              </div>
            </div>
            <ul className={this.state.openTrips ? styles.open : styles.closed}>
              <li>
                <Link to="/trips-events#trips">Trips</Link>
              </li>
              <li>
                <Link to="/trips-events#private">Private Expeditions</Link>
              </li>
              <li>
                <Link to="/trips-events#talks">Talks</Link>
              </li>
              <li>
                <Link to="/trips-events#workshops">Workshops</Link>
              </li>
              <li>
                <Link to="/trips-events#exhibitions">Exhibitions</Link>
              </li>
            </ul>
          </li>
          <li>
            <div className={styles.parent}>
              <Link to="/gallery/">Gallery</Link>
              <div
                className={this.state.openGallery ? styles.more : styles.less}
                onClick={this.toggleGallery}
              >
                <Plus />
              </div>
            </div>
            <ul
              className={this.state.openGallery ? styles.open : styles.closed}
            >
              <li>
                <Link to="/gallery/antarctic">Antarctic</Link>
              </li>
              <li>
                <Link to="/gallery/arctic">Arctic</Link>
              </li>
              <li>
                <Link to="/gallery/emperor-penguins">Emperor Penguins</Link>
              </li>
              <li>
                <Link to="/gallery/falklands-and-south-georgia">
                  Falklands and South Georgia
                </Link>
              </li>
              <li>
                <Link to="/gallery/galapagos">Galapagos</Link>
              </li>
              <li>
                <Link to="/gallery/japan-in-winter">Japan in Winter</Link>
              </li>
              <li>
                <Link to="/gallery/madagascar">Madagascar</Link>
              </li>
              <li>
                <Link to="/gallery/papua-new-guinea">Papua New Guinea</Link>
              </li>
              <li>
                <Link to="/gallery/zambia">Zambia</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/favourite-things/">Favourite Things</Link>
          </li>
          <li>
            <Link to="/stock-photography/">Stock Photography</Link>
          </li>
        </ul>
      </div>
    )
  }
}

export default Menu
