import React from 'react'
import PropTypes from 'prop-types'

import Header from '../components/header/header.js'
import ToggleMenu from '../components/toggleMenu/toggleMenu.js'
import Menu from '../components/menu/menu.js'
import SocialMedia from '../components/socialmedia/socialmedia.js'

import styles from './index.module.scss'

const Layout = ({ children }) => (
  <div className={styles.wrapper}>
    <div className={styles.header}>
      <Header />
      <ToggleMenu />
    </div>
    <div className={styles.menu}>
      <Menu />
      <SocialMedia />
    </div>
    <div className={styles.content}>
      <div className={styles.contentInner}>{children}</div>
    </div>
  </div>
)

Layout.propTypes = {
  children: PropTypes.func,
}

export default Layout
