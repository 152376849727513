import React from 'react'
import styles from './layout.module.scss'
import emperor from '../images/shop/emperor.jpg'
import cold from '../images/shop/cold-places.jpg'
import Layout from '../layouts'

const Shop = () => (
  <Layout>
    <section>
      <h1>Shop</h1>
      <figure>
        <img src={emperor} alt="Emperor: The Perfect Penguin" />
        <figcaption>Cover for Emperor: The Perfect Penguin</figcaption>
      </figure>
      <article>
        <h2>Emperor: The Perfect Penguin</h2>
        <iframe
          title="Youtube"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/vbGyaMDjL2Y?rel=0&amp;showinfo=0"
          frameborder="0"
          allow="autoplay; encrypted-media"
          allowfullscreen
        ></iframe>
        <p>
          <strong>Emperor: The Perfect Penguin</strong> (published by ACC Art
          Books, 26 September 2018) is a love letter from Sue Flood to the
          emperor penguin - the biggest and most photogenic of the 18 species in
          the penguin family and the only member which never sets a foot on dry
          land, spending their entire lives, instead, either on sea ice or
          feeding in the Southern Ocean around Antarctica.
        </p>
        <p>
          It combines more than 200 crisp, clear and beautifully-reproduced
          images taken by Sue Flood during the many hours she’s spent in the
          heart of penguin colonies with behind-the-scenes anecdotes, insights
          and observations about Earth’s remotest, coldest and harshest
          environment. Includes a foreword by Michael Palin.
        </p>
        <h3>Buy</h3>
        <div className={styles.buy}>
          <a
            className={styles.button}
            href="http://amzn.eu/d/epVXSV4"
            target="_blank"
            rel="noreferrer"
          >
            Amazon UK
          </a>
          <a
            className={styles.button}
            href="http://a.co/d/f7HHNzO"
            target="_blank"
            rel="noreferrer"
          >
            Amazon USA
          </a>
        </div>
      </article>
      <figure>
        <img src={cold} alt="" />
        <figcaption>Cover for Cold Places</figcaption>
      </figure>
      <article>
        <h2>Cold Places</h2>
        <p>
          <strong>Cold Places</strong> (New Sue Publishing, April 2011) brings
          together more than 150 of the photographs captured by Sue during more
          than a dozen visits to the Arctic and Antarctic, including shots of
          polar bears, penguins, whales, seals, seabirds, Arctic people,
          glaciers and snowscapes.
        </p>
        <p>
          Each photo is accompanied by a caption telling a personal story about
          the shot. There is also a helpful round-up of the kit and settings
          used to obtain every one of the images. With a foreword by the Hon
          Alexandra Shackleton, grand-daughter of renowned Polar explorer Sir
          Ernest Shackleton
        </p>
        <h3>Buy</h3>
        <div className={styles.buy}>
          <a
            className={styles.button}
            href="http://amzn.eu/d/hC9uQhu"
            target="_blank"
            rel="noreferrer"
          >
            Amazon UK
          </a>
          <a
            className={styles.button}
            href="http://a.co/d/e3apZ0B"
            target="_blank"
            rel="noreferrer"
          >
            Amazon USA
          </a>
        </div>
      </article>
      <article>
        <h2>Signed copies</h2>
        <p>
          Signed/inscribed copies of Sue’s books can be ordered direct from this
          website, at only a modest extra cost to cover gift-wrapping, packaging
          and postage. To find out more or place an order, please email
          <a href="mailto:info@sueflood.com">info@sueflood.com</a>.
        </p>
      </article>
    </section>
  </Layout>
)

export default Shop
