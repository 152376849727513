import React from 'react'
import Link from 'gatsby-link'
import styles from './header.module.scss'

const Header = ({ siteTitle }) => (
  <div className={styles.wrapper}>
      <h1 className={styles.title}><Link to="/">Sue Flood</Link></h1>
      <span className={styles.subtitle}>Award-winning travel & wildlife photographer, tour guide and speaker</span>
  </div>
)

export default Header
